<template>
  <div class="" v-if="cats && currentSubscription">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <div v-if="!isSummary" class="positive" key="cats">
        <transition
          enter-active-class="miniFadeInTop"
          leave-active-class="miniFadeOutBottom"
          mode="out-in"
        >
          <div v-if="cats && products" :key="`cat-${selectedCatIndex}`">
            <div class="mx-auto w-full max-w-4xl flex-grow">
              <product-selection
                :cat="selectedCat"
                :products="products"
                :catIndex="selectedCatIndex"
                :totalCats="cats.length"
                v-if="products && cats"
                :isAdjust="isAdjust"
                @totalsUpdated="setTotals"
              />
            </div>
          </div>
        </transition>
        <div
          class="relative sticky bottom-0 z-20 border-t-2 bg-white"
          v-if="!isSummary"
        >
          <transition
            enter-active-class="miniFadeInTop"
            leave-active-class="miniFadeOutBottom"
            mode="out-in"
          >
            <div
              v-if="subValidation.isWetMax"
              class="absolute left-0 right-0 top-0 text-sm text-indigo-200 shadow-lg"
              :class="{
                '-mt-16 sm:-mt-12': subValidation.isWetMax,
                '-mt-12 sm:-mt-12': !isMultiples
              }"
            >
              <div
                class="flex w-full flex-col items-center justify-center space-x-2 rounded bg-indigo-500 py-2 text-center sm:flex-row"
              >
                <div class="flex items-center space-x-2 sm:flex-row">
                  <font-awesome-icon
                    class="text-base text-white"
                    :icon="['far', 'siren-on']"
                  />
                  <span
                    class="mr-1 font-semibold text-white"
                    v-if="subValidation.isWetMax"
                    >Maximum reached</span
                  >
                  <span
                    class="mr-1 font-semibold text-white"
                    v-if="!isMultiples"
                  >
                    Add
                    <span class="font-semibold text-white">{{
                      needToAdd
                    }}</span>
                    more pouches / trays to continue</span
                  >
                </div>
                <div v-if="subValidation.isWetMax">
                  {{ this.$store.state.core.maxWet }} pouches / trays per cat.

                  <a class="underline" href="mailto:team@thecoolcatclub.com"
                    >Need more?</a
                  >
                </div>
              </div>
            </div>
          </transition>
          <div
            class="-ml-4 -mr-4 flex flex-col items-center rounded-lg bg-white p-4 px-4 pb-4 font-merri font-semibold backdrop-blur sm:flex-row"
          >
            <div class="mb-2 w-full items-center sm:mb-0 md:flex">
              <div class="default-transition mr-4 hidden w-8 md:flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 62.55 64.55"
                  class="text-teal-400"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        class="avatar-bg"
                        d="M59.53,52.21c1.95-2.87,7.2-42.69-3.47-48.55C50.8.77,34.39,14.06,34.39,14.06S18.79-1.93,12.72.19C-1.47,5.14-4.21,49.62,6.65,60,16.31,69.24,52,63.25,59.53,52.21Z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="hidden font-sans sm:flex-col md:flex">
                <div v-if="cats[selectedCatIndex]">
                  {{ isAdjust ? 'Adjusting' : 'Choosing' }} food for
                  {{ cats[selectedCatIndex].name }}
                </div>
                <div class="font-sans text-sm font-normal italic text-gray-600">
                  Adjust or cancel anytime!
                </div>
              </div>
            </div>

            <div
              class="flex w-full flex-grow flex-col items-center justify-between space-y-4 text-right font-sans sm:justify-end md:flex-row"
            >
              <div class="w-full flex-grow">
                <amount-tracker :amounts="totalAmounts">
                  <div class="disabled-popup flex-none">
                    <button
                      @click="nextCat()"
                      class="ml-4 flex-none rounded border-2 border-teal-400 bg-teal-400 p-2 px-4 font-semibold text-white hover:border-teal-300 hover:bg-teal-300"
                      :disabled="!subValidation.isValid || !isMultiples"
                      :class="{
                        'pointer-events-none opacity-50':
                          !subValidation.isValid || !isMultiples
                      }"
                    >
                      Next
                      <font-awesome-icon
                        :icon="['far', 'arrow-right-long']"
                        class="ml-2"
                      />
                    </button></div
                ></amount-tracker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else key="summary" class="container w-full lg:mx-auto">
        <subscription-summary
          :subscription="subscriptionCost"
          :currentSubscriptionCats="
            isLoggedIn ? currentSubscription.items : cats
          "
          :subscriptionTrial="subscriptionCostTrial"
          :subscriptionTrialProducts="subscriptionTrialProducts"
          :plans="plans"
          :products="products"
          :cats="cats"
          v-if="cats && products && isSummary"
          @edit-cat="selectCat"
          :isAdjust="isAdjust"
        />
      </div>
    </transition>

    <div ref="validationMessage" v-if="!isSummary">
      <div v-html="subValidation.message"></div>
    </div>
  </div>
</template>

<script>
import utils from '@/utils';
import { filter, isEmpty } from 'lodash/fp';
import tippy from 'tippy.js';
import ProductSelection from '@/components/products/ProductSelection';
import SubscriptionSummary from '@/components/SubscriptionSummary';
import AmountTracker from '@/components/AmountTracker';
import { mapGetters } from 'vuex';

export default {
  components: {
    AmountTracker,
    ProductSelection,
    SubscriptionSummary
  },

  data() {
    return {
      selectedCatIndex: this.$route.query.plan ? this.$route.query.plan : 0,
      subscriptionCost: null,
      subscriptionCostTrial: null,
      subscriptionTrialProducts: [],
      isSummary: this.$route.meta.isSummary || false,
      isAdjust: this.$route.meta.isSubscribe,
      validationTippy: [],
      cats: null,
      totalAmounts: null,
      plans: [],
      isLoading: false
    };
  },
  watch: {
    subValidation(value) {
      this.initTippy().then(() => {
        if (!value.isValid) {
          this.validationTippy[0].enable();
        } else {
          this.validationTippy[0].disable();
        }
      });
    },
    cats: {
      handler(newCats) {
        // If we have a catId parameter, set the selectedCatIndex
        if (this.$route.params.catId && newCats) {
          const catId = this.$route.params.catId;
          const catIndex = newCats.findIndex((cat) => cat.id === catId);
          if (catIndex !== -1) {
            this.selectedCatIndex = catIndex;
            this.isSummary = false;
          }
        }
        // If we're on the summary page and cats data has changed, update the subscription cost
        else if (this.isSummary && newCats && newCats.length > 0) {
          this.getSubscriptionCost();
        }
      },
      deep: true
    },
    // Watch for route changes to handle browser navigation
    $route: {
      handler(to, from) {
        // Save current cat's changes before changing views
        if (from && !this.isSummary) {
          this.saveCurrentCatChanges();
        }

        // Handle route changes for browser navigation
        if (to.name === 'adjust pricing summary' || to.meta.isSummary) {
          this.isSummary = true;
          // Only update subscription cost when explicitly navigating to summary
          this.getSubscriptionCost();
        } else if (
          to.name === 'adjust pricing cat' &&
          to.params.catId &&
          this.cats
        ) {
          const catId = to.params.catId;
          const catIndex = this.cats.findIndex((cat) => cat.id === catId);
          if (catIndex !== -1) {
            this.selectedCatIndex = catIndex;
            this.isSummary = false;
          }
        } else if (to.name === 'adjust pricing') {
          // Default to first cat or summary based on previous state
          if (this.cats && this.cats.length > 0) {
            this.selectedCatIndex = 0;
            this.isSummary = false;

            // Redirect to the first cat's URL
            this.$router.replace({
              name: 'adjust pricing cat',
              params: { catId: this.cats[0].id },
              query: this.$route.query // Preserve query parameters including restart
            });
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    selectedCat() {
      return this.cats[this.selectedCatIndex];
    },
    isMultiples() {
      if (this.totalAmounts) {
        const totalWet =
          this.totalAmounts['wet_pouch'] +
          this.totalAmounts['wet_tray'] +
          this.totalAmounts['wet_can'] +
          this.totalAmounts['wet_smooth'] +
          this.totalAmounts['wet_tasty'] +
          this.totalAmounts['wet_chunk'];
        const numbers = [totalWet];
        const multiplesOf = numbers.filter((n) => !(n % 15));
        return !isEmpty(multiplesOf);
      }

      return this.totalAmounts;
    },
    needToAdd() {
      if (this.totalAmounts) {
        const totalWet =
          this.totalAmounts['wet_pouch'] +
          this.totalAmounts['wet_tray'] +
          this.totalAmounts['wet_can'] +
          this.totalAmounts['wet_smooth'] +
          this.totalAmounts['wet_tasty'] +
          this.totalAmounts['wet_chunk'];
        return Math.ceil(totalWet / 15) * 15 - totalWet;
      }
      return 0;
    },
    noOfCats() {
      return this.cats.length;
    },
    color() {
      let color = utils.getCatColor(this.selectedCatIndex);
      return color;
    },
    ...mapGetters({
      subValidation: 'subValidation',
      isFirstMonth: 'isFirstMonth',
      isLoggedIn: 'isLoggedIn',
      user: 'getCurrentUser',
      currentSubscription: 'getCurrentSubscription',
      products: 'productGroupsGetter',
      discount: 'getDiscountAmount'
    })
  },
  mounted() {
    this.getCats();

    if (this.$route.params.step === 'summary' || this.$route.meta.isSummary) {
      this.isSummary = true;

      // Add this check to ensure we have cats data before calling getSubscriptionCost
      if (this.cats && this.cats.length > 0) {
        // Call getSubscriptionCost immediately if we have cats data
        this.getSubscriptionCost();
      } else {
        // If cats aren't loaded yet, we'll need to call getSubscriptionCost after they're loaded
        // This will be handled in the updated getCats method
      }
    } else {
      this.isSummary = false;
      // After cats are loaded, we'll update the URL in the getCats method
    }

    // Add event listener for browser back/forward buttons
    window.addEventListener('popstate', this.handlePopState);

    if (this.$route.name === 'adjust pricing') {
    }
  },

  beforeDestroy() {
    // Remove event listener when component is destroyed
    window.removeEventListener('popstate', this.handlePopState);
  },

  methods: {
    // Handle browser back/forward navigation
    handlePopState() {
      // Check current route and update component state accordingly
      const route = this.$route;

      // Save current cat's changes before changing views
      if (!this.isSummary) {
        this.saveCurrentCatChanges();
      }

      if (route.name === 'adjust pricing summary' || route.meta.isSummary) {
        this.isSummary = true;
        // Only update subscription cost when explicitly navigating to summary
        this.getSubscriptionCost();
      } else if (
        route.name === 'adjust pricing cat' &&
        route.params.catId &&
        this.cats
      ) {
        const catId = route.params.catId;
        const catIndex = this.cats.findIndex((cat) => cat.id === catId);
        if (catIndex !== -1) {
          this.selectedCatIndex = catIndex;
          this.isSummary = false;
        }
      } else if (route.name === 'adjust pricing') {
        // Default behavior for main adjust pricing route
        if (this.cats && this.cats.length > 0) {
          this.selectedCatIndex = 0;
          this.isSummary = false;

          // Redirect to the first cat's URL
          this.$router.replace({
            name: 'adjust pricing cat',
            params: { catId: this.cats[0].id },
            query: this.$route.query // Preserve query parameters including restart
          });
        }
      }
    },

    setTotals(payload) {
      this.totalAmounts = payload;
    },
    getCats() {
      this.$store.dispatch('getSubscription').then((sub) => {
        this.plans = sub;
        let cats;
        if (this.currentSubscription?.active) {
          cats = this.currentSubscription
            ? filter('active', this.currentSubscription.items)
            : utils.getStorage('cats', 'local') || undefined;
        } else {
          cats = this.currentSubscription?.items || undefined;
        }

        cats = Object.assign([], cats, {});

        this.cats = cats;
        this.cats.forEach((c) => {
          c.products = isEmpty(c.products)
            ? utils.foodSuggestor(c)
            : c.products;
        });

        // If we have a catId parameter, set the selectedCatIndex after cats are loaded
        if (this.$route.params.catId && this.cats) {
          const catId = this.$route.params.catId;
          const catIndex = this.cats.findIndex((cat) => cat.id === catId);
          if (catIndex !== -1) {
            this.selectedCatIndex = catIndex;
            this.isSummary = false;
          }
        }
        // If we don't have a catId parameter and we're not on the summary page,
        // update the URL with the first cat's ID
        else if (
          !this.isSummary &&
          this.isAdjust &&
          this.cats &&
          this.cats.length > 0 &&
          this.$route.name === 'adjust pricing'
        ) {
          // Update the URL with the first cat's ID
          this.$router.replace({
            name: 'adjust pricing cat',
            params: { catId: this.cats[0].id },
            query: this.$route.query // Preserve query parameters including restart
          });
        }
        // If we're on the summary page, make sure to get the subscription cost
        else if (this.isSummary && this.cats && this.cats.length > 0) {
          this.getSubscriptionCost();
        }
      });
    },
    initTippy: function () {
      return new Promise((resolve) => {
        if (!this.validationTippy.length > 0) {
          this.validationTippy = tippy('.disabled-popup', {
            allowHTML: true,
            animation: 'shift-away',
            maxWidth: 200,
            content: this.$refs.validationMessage
          });
        }
        resolve();
      });
    },
    getDiscount(amount) {
      const discount = utils.calcDiscount(amount);

      return '£' + discount.toFixed(2);
    },
    showModal: function (title, component) {
      this.$store.commit('show_modal', {
        title: title,
        component: component
      });
    },

    nextCat: function () {
      window.scrollTo(0, 0);
      if (this.selectedCatIndex != this.noOfCats - 1) {
        // Save current cat's changes
        this.saveCurrentCatChanges();

        this.selectedCatIndex++;

        // Update the URL with the cat ID
        if (this.isAdjust && this.cats[this.selectedCatIndex]) {
          this.$router.push({
            name: 'adjust pricing cat',
            params: { catId: this.cats[this.selectedCatIndex].id },
            query: this.$route.query // Preserve query parameters including restart
          });
        }

        this.$store.dispatch('sendMpEvent', {
          event: 'Next cat',
          properties: {
            location: this.$route.name,
            type: 'EVT'
          }
        });
      } else {
        // Save current cat's changes before going to summary
        this.saveCurrentCatChanges();

        // Only get subscription cost when going to summary
        this.getSubscriptionCost();
        this.isSummary = true;

        // Update the URL to the summary view
        if (this.isAdjust) {
          this.$router.push({
            name: 'adjust pricing summary',
            query: this.$route.query // Preserve query parameters including restart
          });
        }
      }
    },

    selectCat: function (index) {
      // Save current cat's changes if we're coming from another cat
      if (!this.isSummary && this.selectedCatIndex !== index) {
        this.saveCurrentCatChanges();
      }

      this.isSummary = false;
      this.selectedCatIndex = index;

      // Update the URL with the cat ID
      if (this.isAdjust && this.cats[index]) {
        this.$router.push({
          name: 'adjust pricing cat',
          params: { catId: this.cats[index].id },
          query: this.$route.query // Preserve query parameters including restart
        });
      }
    },

    // Save changes to the current cat
    saveCurrentCatChanges: function () {
      // Make sure we have valid cats and a valid index
      if (!this.cats || !this.cats[this.selectedCatIndex]) return;

      // Remove group key if it exists (same as in getSubscriptionCost)
      this.cats[this.selectedCatIndex].products.forEach((product) => {
        if (product.group) {
          delete product.group;
        }
      });

      // We never call getSubscriptionCost here - it will only be called
      // when explicitly navigating to the summary page
    },

    getSubscriptionCost: function () {
      // Only make API calls when on the summary page
      if (!this.isSummary) {
        return;
      }

      // Make sure we have cats data before proceeding
      if (!this.cats || this.cats.length === 0) {
        return;
      }

      // For each of the cats products remove the group key if it exists
      this.cats.forEach((cat) => {
        cat.products.forEach((product) => {
          if (product.group) {
            delete product.group;
          }
        });
      });

      this.$store
        .dispatch('getSubscriptionCost', this.cats)
        .then((response) => {
          this.subscriptionCost = response;
        })
        .catch(() => {
          // You might want to show an error message to the user here
        });
    },

    setDispatchDate(sub) {
      if (sub) {
        sessionStorage.setItem('dispatchDate', sub.nextDispatchDate);
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
